import {BaseService, Body, DELETE, GET, Headers, PATCH, Path, POST, Query, Response} from 'ts-retrofit';
import {ACCEPT_JSON} from '../config';
import {ListedResponse} from '../../../models/hydratator/listed-response';
import {Client} from '../../../models/clients/client';
import {FilterClientRequest} from '../../../models/requests/filter-client-request';
import {ToData, ToListedResponse} from '../rest-api-hydratator-decorators';
import {ClientTelephoneDescriptor} from '../../../models/clients/client-telephone-descriptor';
import {Contract} from '../../../models/contract';
import {TicketGroup} from '../../../models/tickets/ticket-group';
import {BodyRequest} from '../../../models/body-request';
import {ClientPremiumAssistance} from '../../../models/clients/client-premium-assistance';
import {ZucchettiContract} from '../../../models/zucchetti-contract';
import {Agent} from '../../../models/agent';
import {ContractTree} from '../../../models/contract-tree';

const base = 'clients';

export class ClientsService extends BaseService {

    @POST(`/${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Client)
    async filter(@Body request: FilterClientRequest): Promise<Response<ListedResponse<Client>>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(Client)
    async getById(@Path('clientId') clientId: number): Promise<Response<Client>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}/telephones`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientTelephoneDescriptor)
    async getTelephonesByClientId(@Path('clientId') clientId: number): Promise<Response<ClientTelephoneDescriptor[]>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}/contracts`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async getContractsByClientId(@Path('clientId') clientId: number): Promise<Response<Contract[]>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}/contractsTree`)
    @Headers({...ACCEPT_JSON})
    @ToData(ContractTree)
    async getContractsTreeByClientId(@Path('clientId') clientId: number): Promise<Response<ContractTree[]>> {
        return <Response>{};
    }

    @POST(`/${base}/{clientId}/contracts`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async addContract(@Path('clientId') clientId: number, @Body loginName: BodyRequest<string>): Promise<Response<Contract>> {
        return <Response>{};
    }

    @DELETE(`/${base}/{clientId}/contracts/{contractId}`)
    @Headers({...ACCEPT_JSON})
    @ToData(Contract)
    async removeContract(@Path('clientId') clientId: number, @Path('contractId') contractId: number): Promise<Response<Contract>> {
        return <Response>{};
    }

    @POST(`/${base}/{clientId}/commercialEvaluation`)
    @Headers({...ACCEPT_JSON})
    async newCommercialEvaluation(@Path('clientId') clientId: number,
                                  @Query('contractId') contractId: number,
                                  @Query('idGroup') ticketGroupId: number): Promise<Response<TicketGroup>> {
        return <Response>{};
    }

    @POST(`/${base}/search`)
    @Headers({...ACCEPT_JSON})
    @ToListedResponse(Client)
    async getAll(@Body request: FilterClientRequest): Promise<Response<ListedResponse<Client>>> {
        return <Response>{};
    }

    /***
     * Aggiorna il blocco amministrativo
     * @param clientId
     * @param captive blocco amministrativo
     */
    @PATCH(`/${base}/{clientId}/captive/{captive}`)
    @Headers({...ACCEPT_JSON})
    async updateCaptive(@Path('clientId') clientId: number, @Path('captive') captive: boolean): Promise<Response<boolean>> {
        return <Response>{};
    }

    /***
     * Aggiorna lo stato Dirigenziale
     * @param clientId
     * @param chiefExecutive stato Dirigenziale
     */
    @PATCH(`/${base}/{clientId}/chiefExecutive/{chiefExecutive}`)
    @Headers({...ACCEPT_JSON})
    async updateChiefExecutive(@Path('clientId') clientId: number, @Path('chiefExecutive') chiefExecutive: boolean)
        : Promise<Response<boolean>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}/premiumAssistance`)
    @Headers({...ACCEPT_JSON})
    @ToData(ClientPremiumAssistance)
    async getPremiumAssistance(@Path('clientId') clientId: number): Promise<Response<ClientPremiumAssistance>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{clientId}/premiumAssistance`)
    @Headers({...ACCEPT_JSON})
    async updatePremiumAssistance(@Path('clientId') clientId: number,
                                  @Body premiumAssistance: BodyRequest<boolean>): Promise<Response<void>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}/zucchettiContracts`)
    @Headers({...ACCEPT_JSON})
    @ToData(ZucchettiContract)
    async getZucchettiContracts(@Path('clientId') clientId: number): Promise<Response<ZucchettiContract[]>> {
        return <Response>{};
    }

    @GET(`/${base}/{clientId}/zucchettiAgent`)
    @Headers({...ACCEPT_JSON})
    @ToData(Agent)
    async getZucchettiAgent(@Path('clientId') clientId: number): Promise<Response<Agent>> {
        return <Response>{};
    }

    @PATCH(`/${base}/{clientId}/recall`)
    @Headers({...ACCEPT_JSON})
    async updateSangomaRecall(@Path('clientId') clientId: number,
                                  @Body recall: BodyRequest<boolean>): Promise<Response<void>> {
        return <Response>{};
    }
}
