import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit {

    private static inputElementCounter = 1;

    private readonly _inputElementId: number;

    get inputElementId(): string {
        return 'inputElement' + this._inputElementId;
    }

    timedDate: Date;

    @Input()
    model?: Date;

    @Output()
    modelChange: EventEmitter<Date> = new EventEmitter<Date>();

    @Input()
    id: string;

    @Input()
    showTime = true;

    @Input()
    label = '';

    @Input()
    disabled = false;

    @Input()
    readOnly = false;

    get hasLabel(): boolean {
        return this.label !== '';
    }

    @Input()
    labelWidth = '200px';

    @Input()
    inputWidth = '200px';

    constructor() {
        this._inputElementId = ++DatetimeComponent.inputElementCounter;
    }

    ngOnInit(): void {
        this.timedDate = this.model ? new Date(this.model.getUTCFullYear(),
            this.model.getUTCMonth(),
            this.model.getUTCDate(),
            this.model.getUTCHours(),
            this.model.getUTCMinutes(),
            0
        ) : null;
    }

    onChange(dateValue: Date) {
        if (dateValue == null) {
            this.modelChange.emit(null);
        } else {
            const resultDate = new Date(Date.UTC(
                dateValue.getFullYear(),
                dateValue.getMonth(),
                dateValue.getDate(),
                dateValue.getHours(),
                dateValue.getMinutes(),
                0)
            );
            this.modelChange.emit(resultDate);
        }
    }

}
