export const environment = {
    production: true,
    mftItaliaWebsite: 'https://www.mftitalia.it',
    baseApi: 'https://dev.api.mftitalia.it/api',
    hubUrl: 'https://dev.api.mftitalia.it/hubs/notifications',
    baseMftweb: 'https://dev.mftweb.mftitalia.it/MFTWEB',
    baseClientArea: 'https://dev.areacliente.mftitalia.it',
    internalMftweb: 'http://192.168.1.203/MFTWEB',
    baseEasyCast: 'https://localhost:4300'
};
